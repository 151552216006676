import React from "react";
import { Tag } from "@trace-one/design-system";
import styles from "routes/Tasks/TasksList/components/TableTasks.module.less";
import moment from "moment";
import Icon from "components/Icon";

interface EstimatedEndDateColumn {
  getColor: (endDate: Date, initialDueDateUtc: Date, status: string) => string;
}

interface Record {
  id: string;
  originalData: {
    estimatedEndDateCheckBox?: boolean;
    hardDateConstraint?: {
      EndDate?: boolean;
    };
  };
}

interface EstimatedEndDateCellProps {
  endDate: Date;
  isEstimatedEndDateChecked: boolean;
  canBeLocked: boolean;
  record: Record;
  records: Record[];
  status: string;
  initialDueDateUtc: Date;
  EstimatedEndDateColumn: EstimatedEndDateColumn;
}

const formatEndDate = (endDate: Date): string =>
  moment(endDate).format("DD/MM/YYYY");

const renderIcon = (isChecked: boolean) => {
  if (isChecked) {
    return (
      <div className={styles.calender}>
        <Icon name="Calendar" />
      </div>
    );
  }
};

const renderTag = (
  endDate: Date,
  EstimatedEndDateColumn: EstimatedEndDateColumn,
  initialDueDateUtc: Date,
  status: string
) => (
  <Tag
    className={styles.setTagEstimatedEndDate}
    color={EstimatedEndDateColumn.getColor(endDate, initialDueDateUtc, status)}
    label={formatEndDate(endDate)}
    size="medium"
    mode="light"
  />
);

const isCheckboxDisabled = (records: Record[], recordId: string): boolean =>
  records.some(
    item =>
      (item.originalData.estimatedEndDateCheckBox ||
        item.originalData.hardDateConstraint?.EndDate) &&
      item.id !== recordId
  );

const EstimatedEndDateCell: React.FC<EstimatedEndDateCellProps> = ({
  endDate,
  isEstimatedEndDateChecked,
  canBeLocked,
  record,
  records,
  status,
  initialDueDateUtc,
  EstimatedEndDateColumn,
}) => {
  const isEnable = isCheckboxDisabled(records, record.id);
  return (
    <div className={styles.estimatedEndDateCheckBox}>
      {canBeLocked ? (
        <div
          className={
            isEnable || isEstimatedEndDateChecked
              ? styles.tagDisable
              : styles.tagEnable
          }
        >
          <span>{formatEndDate(endDate)}</span>
          <span className={styles.icons}>
            {renderIcon(isEstimatedEndDateChecked)}
          </span>
        </div>
      ) : (
        renderTag(endDate, EstimatedEndDateColumn, initialDueDateUtc, status)
      )}
      {canBeLocked && (
        <input
          type="checkbox"
          checked={isEstimatedEndDateChecked}
          id={`checkbox-${record.id}`}
          disabled={isEnable}
          onChange={() => {}}
          className={styles.estimatedEndDatecheckbox}
        />
      )}
    </div>
  );
};

export default EstimatedEndDateCell;
