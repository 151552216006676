/* istanbul ignore file */
import { DurationColumn, ColumnStore } from "bryntum-gantt";

export default class EstimatedDurationColumn extends DurationColumn {
  static get type() {
    return "estimatedDuration";
  }

  static get isGanttColumn() {
    return true;
  }

  static get defaults() {
    return {
      text: "Duration",
      htmlEncode: false,
    };
  }

  isValid(value) {
    return !Number.isNaN(Number.parseFloat(value));
  }

  formatDuration(value) {
    return value < 1 ? "<1" : `${Math.round(value)}`;
  }

  renderer(data) {
    const {
      record: {
        isProjectMilestone,
        originalData: { taskStatus, estimatedDuration },
      },
      value: { magnitude },
    } = data;

    if (isProjectMilestone || !this.isValid(estimatedDuration)) {
      return "";
    }

    const durationValue =
      taskStatus === "InProgress" ? magnitude : estimatedDuration;

    if (!this.isValid(durationValue)) {
      return "";
    }

    return this.formatDuration(durationValue);
  }
}

ColumnStore.registerColumnType(EstimatedDurationColumn);
