import { ApplicationTypes } from "constants/Constants";

export default function getAppName(appId: string | number) {
  const appplicationLabels = {
    [ApplicationTypes.QUALITY_PORTAL]: "Quality Portal",
    [ApplicationTypes.PACK_PORTAL]: "Pack Portal",
    [ApplicationTypes.PDM]: "PDM",
    [ApplicationTypes.BUSINESS_INTELLIGENCE]: "Business Intelligence",
    [ApplicationTypes.INSIGHT]: "Insight",
    [ApplicationTypes.TON]: "Network",
    [ApplicationTypes.PROJECT]: "Project",
    [ApplicationTypes.QMS]: "QMS",
    [ApplicationTypes.SPEC]: "SPEC",
    [ApplicationTypes.RFX]: "RFX",
    [ApplicationTypes.NEW_INSIGHT]: "Insight",
    [ApplicationTypes.MASTER_DATA]: "Master Data",
  };

  return {
    appLabel: appplicationLabels[+appId],
  };
}
