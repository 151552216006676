export enum LANGUAGES {
  ENGLISH = "en-US",
  FRENCH = "fr-FR",
}

export const HELP_CENTER_URLS = {
  [LANGUAGES.ENGLISH]: window.env.TO_HELP_LINK_EN,
  [LANGUAGES.FRENCH]: window.env.TO_HELP_LINK_FR,
};

export enum ApplicationTypes {
  QUALITY_PORTAL = 0,
  PACK_PORTAL = 1,
  PDM = 8,
  PDMFIND = 23,
  PDMNA = 22,
  BUSINESS_INTELLIGENCE = 9,
  INSIGHT = 21,
  TON = 40,
  PROJECT = 50,
  QMS = 51,
  SPEC = 70,
  RFX = 80,
  MASTER_DATA = 100,
  NEW_INSIGHT = 110,
  ADMINISTRATION = 90,
}
