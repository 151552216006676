import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { selectUserId } from "common/selectors/users.selectors";

import useRouteSearchParams from "../../../hooks/useRouteSearchParams";
import { ApplicationTypes } from "constants/Constants";
import { backToAppStorage } from "utils/webStorage";
import getAppName from "./getAppName";

interface BackData {
  from?: string; // url of the application from where we came here
  appid?: string; // app id from which came to this page
}

const appGraphicMap = {
  "0": "app-logo-qp-full",
  "1": "app-logo-pp-full",
  "8": "app-logo-pdm-full",
  "9": "app-logo-bi-full",
  "22": "app-logo-pdm_na-full",
  "23": "app-logo-pdm-full",
  "40": "app-logo-ton-full",
  "50": "app-logo-project-full",
  "51": "app-logo-qms-full",
  "70": "app-logo-spec-full",
  "80": "app-logo-rfx-full",
  "90": "app-logo-admin-full",
  "100": "app-logo-md-full",
  "110": "app-logo-insight-full",
};

function isURL(url: string) {
  if (!url) {
    return false;
  }

  try {
    new URL(url);
    return true;
  } catch (err) {
    // no need to print the error.. this is just a util to know if the url is valid
    return false;
  }
}

function validateBackData(data: BackData) {
  return (
    isURL(data?.from) &&
    Object.values(ApplicationTypes).includes(parseInt(data?.appid))
  );
}

function useInitialBackData({ userId }: { userId: string }) {
  return useMemo<BackData>(() => {
    const initialData = backToAppStorage.get();
    const isValid =
      validateBackData(initialData) && initialData.userId === userId;
    return isValid ? initialData : null;
  }, []);
}

const useBackButton = () => {
  const userId = useSelector(selectUserId) as string;
  const { from, appid } = useRouteSearchParams<BackData>();

  const initialData = useInitialBackData({ userId });
  const [data, setData] = useState(initialData);
  const { appLabel } = useMemo(() => getAppName(data?.appid), [data?.appid]);

  const handleBackClick = () => {
    window.location.assign(data.from);
    backToAppStorage.remove();
  };

  useEffect(() => {
    const data = { from, appid };
    /*
     from - url of the application from where we came here
     appid - app id from which came to this page
    */

    if (from && appid) {
      if (validateBackData(data)) {
        setData(data);
        backToAppStorage.set({ ...data, userId });
      } else {
        setData(null);
        backToAppStorage.remove();
      }
    }
  }, [from, appid]);

  useEffect(() => {
    return () => {
      if (appid && from) {
        handleBackClick();
      }
    };
  }, []);

  return {
    isBackButtonVisible: !!validateBackData(data),
    from: data?.from,
    appid: data?.appid,
    handleBackClick,
    appLabel,
    appIcon: appGraphicMap[data?.appid],
  };
};

export default useBackButton;
