import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Header } from "@trace-one/business-components";
import { Container } from "@trace-one/design-system";
import { useIntl } from "react-intl";
import { useReactOidc } from "@axa-fr/react-oidc-context";
import { navConfig, adminNavConfig } from "services/messages/utils";
import { useLegalMonster } from "../legalMonster";
import {
  selectPermissions,
  selectUserId,
} from "common/selectors/users.selectors";
import useErrorActions from "hooks/useErrorActions";
import useTrigerCreationPopUp from "utils/useTrigerCreationPopUp";
import { HELP_CENTER_URLS } from "constants/Constants";
import { selectAccessToken } from "common/selectors/oidc.selectors";
import useBackButton from "./hooks/useBackButton";
import styles from "./Main.module.less";

export const MainLayout = ({ children }) => {
  const intl = useIntl();
  const { logout } = useReactOidc();
  const userId = useSelector(selectUserId);
  const permissions = useSelector(selectPermissions);
  const accessToken = useSelector(selectAccessToken);
  const { appIcon, appLabel, handleBackClick, isBackButtonVisible } =
    useBackButton();
  const { handleCreationPopUp } = useTrigerCreationPopUp();
  const { isMasquerade } = permissions;

  useErrorActions();
  useLegalMonster();

  const handleOnLogout = () => {
    if (handleCreationPopUp({ shuldLogout: true })) {
      return;
    }

    logout();
  };

  return (
    <>
      <Header
        userId={userId}
        isMasquerade={isMasquerade}
        onLogout={handleOnLogout}
        homeUrl="/home"
        appName="Project"
        appLogo="project"
        routes={navConfig(intl, permissions)}
        appSwitcherProps={{
          accessToken,
          excludeApps: [50],
        }}
        redirectBanner={{
          visible: isBackButtonVisible,
          bannerTitle: `${intl.formatMessage({
            id: "components.backTo",
          })} ${appLabel}`,
          appIconName: appIcon,
          onBackClick: handleBackClick,
        }}
        helpLinks={HELP_CENTER_URLS}
        {...(permissions.isAdmin && {
          displayAdminUserAndAccessRoute: true,
          adminUserAndAccessUrl: window.env.UI_ADMIN_USERS_ACCESS,
        })}
        adminSubRoutes={adminNavConfig(intl, permissions)}
        isNameShown={false}
      />
      <Container
        className={isBackButtonVisible && styles.withPreviousAppBanner}
      >
        {children}
        <div id="notificationsRoot" />
      </Container>
    </>
  );
};

MainLayout.propTypes = {
  children: PropTypes.any,
};
