// TODO Test this file!
/* istanbul ignore file */
import React, { useState, useEffect, useRef, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { BryntumProject } from "components/Bryntum/BryntumProject";
import { BryntumToolBar } from "components/Bryntum/BryntumToolBar";
import { useLanguagePreference, Spinner } from "@trace-one/react-components";
import { createGanttConfig } from "services/messages/bryntum";
import { bryntumColumns } from "constants/bryntumSettings";
import TraceOneWeekPicker from "components/TraceOneWeekPicker/TraceOneWeekPicker";
import { registerData } from "redux/actions/ProjectCreation";
import { Tooltip } from "@trace-one/design-system";
import {
  selectProjectTypeId,
  selectEndDate,
  selectStartDate,
  selectIsBryntumLoading,
  selectBryntumModalData,
  selectUserResponsibilities,
  selectTeam,
  selectProjectId,
  selectIsSaved,
  selectTasks,
  selectProjectCreationSavedData,
  selectProjectCreationCurrentData,
} from "common/selectors/projects.selectors";
import { fetchBryntumData } from "redux/actions/Bryntum";
import { selectValidatedProducts } from "common/selectors/filteredProducts.selectors";
import { statusNames } from "constants/projects";
import styles from "./BryntumTableOrGantt.module.less";
import {
  setEndDate,
  setStartDate,
  getAPIReadyInfoFromTasks,
  zoomToFit,
  wait,
} from "components/Bryntum/Utils";
import moment from "moment";
import { getBusinessDayAfterDuration } from "utils/DateUtils";
import { ISO_STRING_FORMAT } from "constants/index";
import {
  hardDateComponent,
  projectHeader,
} from "routes/Projects/defineMessages";
import { selectBryntumColumnWidths } from "common/selectors/ganttView.selector";

const BryntumTableOrGantt = ({ shouldrefresh, setRefresh }) => {
  const isSaved = useSelector(selectIsSaved);
  const userResponsibilities = useSelector(selectUserResponsibilities);
  const bryntumModelData = useSelector(selectBryntumModalData);
  const isLoading = useSelector(selectIsBryntumLoading);
  const teams = useSelector(selectTeam);
  const validatedProducts = useSelector(selectValidatedProducts);
  const startDate = useSelector(selectStartDate);
  const tasks = useSelector(selectTasks);
  const [config, setConfig] = useState();
  const languageCode = useLanguagePreference();
  const endDate = useSelector(selectEndDate);
  const projectTypeId = useSelector(selectProjectTypeId);
  const projectId = useSelector(selectProjectId);
  const dispatch = useDispatch();
  const intl = useIntl();
  const ganttRef = useRef();
  const [datePickerDisabled, setDatePickerDisabled] = useState(false);
  const bryntumColumnWidths = useSelector(selectBryntumColumnWidths);
  const projectDataSaved = useSelector(selectProjectCreationSavedData);
  const projectCurrentData = useSelector(selectProjectCreationCurrentData);

  useEffect(() => {
    if (isSaved || shouldrefresh) {
      dispatch(
        fetchBryntumData({
          projectId,
          projectTypeId,
          languageCode,
        })
      );
      setRefresh(false);
    }
  }, [isSaved]);

  const enableEndDateFromDate = useMemo(() => {
    if (ganttRef?.current?.project?.duration) {
      return getBusinessDayAfterDuration(ganttRef?.current?.project?.duration);
    }
  }, [ganttRef?.current?.project?.duration]);

  const getTeamResponsibilities = () => {
    return userResponsibilities.map(responsibility => {
      const rteam = teams.responsibilities.find(
        rteam => rteam.id === responsibility.id
      );
      const valRet = {
        ...responsibility,
        responsibilityId: responsibility.id,
        responsibilityName: responsibility.text,
      };
      if (rteam) {
        const userId = rteam.userIdSelected;
        const userName = rteam.users.find(user => user.value === userId)?.text;
        return {
          ...valRet,
          userId,
          userName,
        };
      }
      return valRet;
    });
  };

  const setTaskEndDate = async data => {
    const { oldValue, value, userAction } = data;
    if (!userAction) {
      return;
    }

    const previousDate =
      oldValue ?? new Date(data.source.eventRecord.originalData.dueDateUtc);
    const { project } = ganttRef.current;
    const duration = project.calendar.calculateDurationMs(previousDate, value);
    const { finalDate: startDate } = project.calendar.accumulateWorkingTime(
      project.startDate,
      duration,
      true
    );

    let startDateMoment = moment(startDate, ISO_STRING_FORMAT);
    if (!startDateMoment.isBusinessDay()) {
      startDateMoment = startDateMoment.nextBusinessDay();
    }
    await project.setStartDate(startDateMoment.toDate());
  };

  const checkIfNoEndDateStateHasChanged = (records, tasksWithIntialState) => {
    return records.every(r => {
      const initialData = tasksWithIntialState.get(r.id);
      if (!initialData) {
        return true;
      }

      return (
        initialData?.hardDateConstraint?.EndDate ===
        r.originalData?.hardDateConstraint?.EndDate
      );
    });
  };

  const EstimatedEndDatecheckBoxChange = async ({ record, records }) => {
    await wait(0);
    const anyCheckboxInSelectedState = records.some(
      rec =>
        rec.originalData.estimatedEndDateCheckBox ||
        rec.originalData.hardDateConstraint?.EndDate
    );

    const tasksWithIntiialState = new Map(
      record.project.initialConfig.eventsData[0].children
        .flatMap(x => x.children)
        .map(task => [task.id, task])
    );

    setDatePickerDisabled(anyCheckboxInSelectedState);
    if (checkIfNoEndDateStateHasChanged(records, tasksWithIntiialState)) {
      dispatch(registerData(projectDataSaved));
      return;
    }

    const updatedTasks = projectCurrentData.tasks.map(task => {
      if (task.taskId === record.originalData.id) {
        if (record.originalData.estimatedEndDateCheckBox) {
          return {
            ...task,
            fixedEndDate: new Date(
              record.originalData.estimatedEndDateSelection ||
                record.originalData.dueDateUtc
            ),
          };
        } else {
          return {
            ...task,
            fixedEndDate: null,
          };
        }
      }

      return task;
    });

    const updatedProjectData = { ...projectDataSaved, tasks: updatedTasks };
    dispatch(registerData(updatedProjectData));
  };

  useEffect(() => {
    const project = ganttRef.current;
    const columns = bryntumColumns({
      intl,
      EstimatedEndDatecheckBoxChange,
      setTaskEndDate,
      project,
      bryntumColumnWidths,
    });
    if (!isLoading) {
      const config = createGanttConfig({
        bryntumModelData,
        columns: columns.projectWizardColumns,
        validatedProducts,
        teams: getTeamResponsibilities(),
        allResponsibilities: userResponsibilities,
        intl,
        canEdit: true,
        tasks,
      });
      setConfig(config);
    }
  }, [
    isLoading,
    validatedProducts,
    teams,
    languageCode,
    intl,
    bryntumModelData,
    userResponsibilities,
    projectCurrentData?.tasks?.length,
  ]);

  const onChange = data => {
    dispatch(registerData(data));
  };

  function onGanttReady() {
    setStartDate({
      startDate: startDate ?? moment.utc().format(ISO_STRING_FORMAT),
      ganttRef,
      onChange,
    });

    ganttRef.current.project.on({
      dataReady() {
        onGanttChange();
      },
    });
  }

  const onGanttChange = () => {
    const ganttPayload = generateGanttPayload(
      ganttRef,
      statusNames.Draft,
      true
    );

    if (ganttPayload) {
      const { endDateUtc, startDateUtc, tasks } = ganttPayload;

      onChange({
        endDate: moment(endDateUtc).startOf("day").format(ISO_STRING_FORMAT),
        startDate: moment(startDateUtc)
          .startOf("day")
          .format(ISO_STRING_FORMAT),
        tasks,
      });
    }
    zoomToFit({ ganttRef });
  };

  const generateGanttPayload = (ganttRef, status, isProjectOwner) => {
    if (
      ganttRef == null ||
      ganttRef.current == null ||
      ganttRef.current.project === undefined
    ) {
      return null;
    }
    return {
      startDateUtc: moment(ganttRef.current.project.startDate).format(
        ISO_STRING_FORMAT
      ),
      endDateUtc: moment(ganttRef.current.project.endDate).format(
        ISO_STRING_FORMAT
      ),
      tasks: getAPIReadyInfoFromTasks(ganttRef.current.tasks, [], {
        projectStatus: status,
        isProjectOwner,
      }),
    };
  };

  function onStartDateChange(startDate) {
    setStartDate({
      ganttRef,
      startDate,
      onChange,
    });
  }

  function onEndDateChange(endDate) {
    setEndDate({
      ganttRef,
      endDate,
      onChange,
    });
  }

  if (isLoading)
    return (
      <div className={styles.loading}>
        <Spinner />
      </div>
    );

  const renderDatePickerSection = () => (
    <Tooltip
      title={
        datePickerDisabled
          ? intl.formatMessage(projectHeader.tooltipStartandEndDate)
          : ""
      }
      placement="top"
      size="large"
    >
      <span className={styles.header}>
        <div className={styles.dateWrapper}>
          <div className={styles.date}>
            <span className={styles.label}>
              <FormattedMessage
                id="projects.creation.planning.components.bryntumTableOrGantt.startDate"
                defaultMessage="Start date"
              />
            </span>
            <TraceOneWeekPicker
              name="startDate"
              value={startDate}
              onChange={onStartDateChange}
              disabled={datePickerDisabled}
            />
          </div>
          <div className={styles.date}>
            <span className={styles.label}>
              <FormattedMessage
                id="projects.creation.planning.components.bryntumTableOrGantt.endDate"
                defaultMessage="End date"
              />
            </span>
            <TraceOneWeekPicker
              enableFrom={enableEndDateFromDate}
              name="endDate"
              value={endDate}
              onChange={onEndDateChange}
              disabled={datePickerDisabled}
            />
          </div>
        </div>
      </span>
    </Tooltip>
  );

  return (
    <div className={styles.bryntumContainer}>
      {bryntumModelData?.hasLockableTask && (
        <div className={styles.hardDateTitle}>
          {intl.formatMessage(hardDateComponent.fixableTask)}
        </div>
      )}
      {renderDatePickerSection()}
      <BryntumProject
        config={config}
        ganttRef={ganttRef}
        onGanttReady={onGanttReady}
        isGanttViewFiltered
      />
      {ganttRef.current && (
        <BryntumToolBar
          config={config}
          ganttRef={ganttRef}
          displayCriticalPathSwitch={true}
        />
      )}
    </div>
  );
};

export default BryntumTableOrGantt;
