/* istanbul ignore file */
import { defineMessages } from "react-intl";
import { DurationField, DateHelper, StringHelper } from "bryntum-gantt";
import moment from "moment-business-days";
import { Icon } from "@trace-one/design-system";
import { renderToString } from "react-dom/server";
import { getBusinessDayAfterDuration } from "utils/DateUtils";

const renderTooltipForProduct = ({
  toolTip,
  isSubProcessForTradeItems,
  isSubProcessForManufacturedItems,
}) => {
  if (
    toolTip &&
    (!!isSubProcessForTradeItems || !!isSubProcessForManufacturedItems)
  ) {
    const { netContentValue, netContentUnitText, gtin } = toolTip;
    return `<div>GTIN: ${gtin} <br/> ${netContentValue} ${netContentUnitText} </div>`;
  }
  return null;
};

export const STATUS_CREATION = "CREATION";

export const iconRender = () => {
  return renderToString(
    <Icon name="more-info" size="small" color="grey-5"></Icon>
  );
};

export const bryntumColumns = ({
  intl,
  showProjections,
  wasRun,
  EstimatedEndDatecheckBoxChange,
  setTaskEndDate,
  project,
  bryntumColumnWidths, // the names provided in the byrntum column widths is same as feilds corresponsing to the column name, please use the same.
}) => {
  const planningTableHead = defineMessages({
    tableName: {
      id: "constants.bryntumSettings.tableName",
      defaultMessage: "Name",
    },
    tableResponsUser: {
      id: "constants.bryntumSettings.tableResponsUser",
      defaultMessage: "Responsibility/Username",
    },
    tableDate: {
      id: "constants.bryntumSettings.tableDate",
      defaultMessage: "Start Date",
    },
    tableDuration: {
      id: "constants.bryntumSettings.timeAllocated",
      defaultMessage: "Time allocated",
    },
    tableEndDate: {
      id: "constants.bryntumSettings.tableEndDate",
      defaultMessage: "Estimate End Date",
    },
    projectTableName: {
      id: "constants.bryntumSettings.projectTableName",
      defaultMessage: "Name",
    },
    projectTableResponsUser: {
      id: "constants.bryntumSettings.projectTableResponsUser",
      defaultMessage: "Responsibility/Username",
    },
    projectTableStatus: {
      id: "constants.bryntumSettings.projectTableStatus",
      defaultMessage: "Status",
    },
    projectTableStartDate: {
      id: "constants.bryntumSettings.projectTableStartDate",
      defaultMessage: "Start Date",
    },
    projectTableDuration: {
      id: "constants.bryntumSettings.projectTableTimeallocated",
      defaultMessage: "Time allocated",
    },
    projectTableInitialDueDate: {
      id: "constants.bryntumSettings.projectTableInitialDueDate",
      defaultMessage: "Initial end date",
    },
    projectTableInitialDueDateToolTip: {
      id: "constants.bryntumSettings.projectTableInitialDueDateToolTip",
      defaultMessage: "Calculated at project creation",
    },
    projectTableEstimatedDueDateToolTip: {
      id: "constants.bryntumSettings.projectTableEstimatedDueDateToolTip",
      defaultMessage:
        "Thoses dates are calculated as the project is moving forward. The projections are just rough estimations.",
    },
    projectTableDueDate: {
      id: "constants.bryntumSettings.projectTableDueDate",
      defaultMessage: "Estimated end date",
    },
    projectTableEndDate: {
      id: "constants.bryntumSettings.projectTableEndDate",
      defaultMessage: "Actual End Date",
    },
    projectTableTimetracked: {
      id: "constants.bryntumSettings.projectTableTimetracked",
      defaultMessage: "Time tracked",
    },
  });

  return {
    projectWizardColumns: [
      {
        type: "name",
        text: intl.formatMessage(planningTableHead.tableName),
        field: "name",
        flex: 1,
        editor: false,
        draggable: false,
        tooltipRenderer: ({ record }) => renderTooltipForProduct(record),
        renderer: ({ value, cellElement, record: { originalData } }) => {
          if (!originalData?.isUserTask) {
            cellElement.style.setProperty("font-weight", "500", "important");
            cellElement.style.setProperty("color", "#6e6e6e", "important");
          } else {
            cellElement.style.setProperty("font-weight", "300", "important");
            cellElement.style.setProperty("color", "#6e6e6e", "important");
          }
          return value;
        },
      },
      {
        type: "userResponsibility",
        text: intl.formatMessage(planningTableHead.tableResponsUser),
        locked: true,
        editor: false,
        flex: 1,
        field: "userResponsibility",
        draggable: false,
      },
      {
        type: "date",
        text: intl.formatMessage(planningTableHead.tableDate),
        format: "DD/MM/YYYY",
        wasRun,
        field: "startDate",
        flex: 1,
        locked: true,
        editor: false,
        draggable: false,
      },
      {
        uuid: "duration",
        type: "estimatedDuration",
        wasRun,
        text: intl.formatMessage(planningTableHead.tableDuration),
        locked: false,
        flex: 1,
        draggable: false,
        editor: new DurationField({
          decimalPrecision: 0,
          width: 200,
          allowNegative: false,
          listeners: {
            change: e => {
              e.source.value = e.value.magnitude;
              e.source.eventRecord.originalData.estimatedDuration =
                e.value.magnitude;
              if (e?.source?.eventRecord?.originalData?.isTimerTask) {
                e.source.value = e.value.magnitude < 0 ? "0 day" : e.value;
              } else {
                e.source.value = e.value.magnitude < 1 ? "1 day" : e.value;
              }
            },
            input: e => {
              if (e.source.eventRecord.originalData.isTimerTask) {
                e.source.value = e.value.magnitude < 0 ? "0 day" : e.value;
              } else {
                e.source.value = e.value.magnitude < 1 ? "1 day" : e.value;
              }
            },
          },
        }),
      },
      {
        type: "initialDueDateUtcCreation",
        text: intl.formatMessage(planningTableHead.projectTableInitialDueDate),
        flex: 1,
        wasRun,
        locked: true,
        editor: false,
        draggable: false,
        tooltip: intl.formatMessage(
          planningTableHead.projectTableInitialDueDateToolTip
        ),
        headerRenderer: function () {
          return `<div style="display: flex; align-items: center;"><span style="margin-right: 5px;"> ${intl.formatMessage(
            planningTableHead.projectTableInitialDueDate
          )}</span><span>${iconRender()}</span</div>`;
        },
      },
      {
        type: "dueDateUtcCreation",
        text: intl.formatMessage(planningTableHead.tableEndDate),
        flex: 1,
        locked: true,
        field: "endDate",
        editor: {
          type: "datefield",
          format: "DD/MM/YYYY",
          picker: {
            disabledDates: value =>
              ["6", "0"].includes(DateHelper.format(value, "d")),
          },
          editable: false,
          listeners: {
            focusIn: ({ source }) => {
              if (!project.startDate || !source.value) {
                return;
              }

              const currentDate = new Date();
              currentDate.setDate(currentDate.getDate() + 1);

              const newDuration = moment(source.value).businessDiff(
                moment(project.startDate)
              );
              const tempDate = getBusinessDayAfterDuration(newDuration);

              const dateSelected =
                tempDate.format("d") === "1"
                  ? tempDate.subtract(2, "days")
                  : tempDate;

              source.min = dateSelected.toDate();
              source.picker.activeDate = dateSelected.toDate();
            },
            change: setTaskEndDate,
          },
        },
        EstimatedEndDatecheckBoxChange,
        draggable: false,
        wasRun,
        tooltip: intl.formatMessage(
          planningTableHead.projectTableEstimatedDueDateToolTip
        ),
        headerRenderer: function () {
          return `<div style="display: flex; align-items: center;"><span style="margin-right: 5px;"> ${intl.formatMessage(
            planningTableHead.tableEndDate
          )}</span><span>${iconRender()}</span</div>`;
        },
      },
    ],
    projectFolderColumns: [
      {
        type: "name",
        text: intl.formatMessage(planningTableHead.projectTableName),
        field: "name",
        width: bryntumColumnWidths.name,
        editor: false,
        draggable: false,
        tooltipRenderer: ({ record }) => renderTooltipForProduct(record),
        hidden: false,
        renderer: ({ value, cellElement, record: { originalData } }) => {
          if (!originalData?.isUserTask) {
            cellElement.style.setProperty("font-weight", "500", "important");
            cellElement.style.setProperty("color", "#6e6e6e", "important");
          } else {
            cellElement.style.setProperty("font-weight", "300", "important");
            cellElement.style.setProperty("color", "#6e6e6e", "important");
          }
          return value;
        },
      },
      {
        type: "userResponsibility",
        field: "userResponsibility",
        text: intl.formatMessage(planningTableHead.projectTableResponsUser),
        width: bryntumColumnWidths.userResponsibility,
        locked: true,
        editor: false,
        draggable: false,
        hidden: false,
      },
      {
        type: "status",
        text: intl.formatMessage(planningTableHead.projectTableStatus),
        locked: true,
        editor: false,
        field: "status",
        width: bryntumColumnWidths.status,
        draggable: false,
        hidden: false,
      },
      {
        type: "date",
        text: intl.formatMessage(planningTableHead.projectTableStartDate),
        format: wasRun ? "DD/MM/YYYY HH:mm" : "DD/MM/YYYY",
        field: "startDate",
        width: bryntumColumnWidths.startDate,
        editor: false,
        draggable: false,
        hidden: false,
      },
      {
        type: "timeTracked",
        text: intl.formatMessage(planningTableHead.projectTableTimetracked),
        field: "timeTracked",
        width: bryntumColumnWidths.timeTracked,
        locked: true,
        editor: false,
        draggable: false,
        hidden: false,
      },
      {
        uuid: "duration",
        type: "estimatedDuration",
        text: intl.formatMessage(planningTableHead.projectTableDuration),
        locked: false,
        wasRun,
        width: bryntumColumnWidths.fullDuration,
        draggable: false,
        hidden: !showProjections,
        editor: new DurationField({
          width: 200,
          decimalPrecision: 0,
          listeners: {
            change: e => {
              e.source.value = e.value.magnitude;
              e.source.eventRecord.originalData.estimatedDuration =
                e.value.magnitude;
              if (e?.source?.eventRecord?.originalData?.isTimerTask) {
                e.source.value = e.value.magnitude < 0 ? "0 day" : e.value;
              } else {
                e.source.value = e.value.magnitude < 1 ? "1 day" : e.value;
              }
            },
            input: e => {
              if (e.source.eventRecord.originalData.isTimerTask) {
                e.source.value = e.value.magnitude < 0 ? "0 day" : e.value;
              } else {
                e.source.value = e.value.magnitude < 1 ? "1 day" : e.value;
              }
            },
          },
        }),
      },
      {
        type: "initialDueDateUtc",
        field: "initialDueDateUtc",
        text: intl.formatMessage(planningTableHead.projectTableInitialDueDate),
        hidden: !showProjections,
        width: bryntumColumnWidths.initialDueDateUtc,
        locked: true,
        editor: false,
        wasRun,
        draggable: false,
        tooltip: intl.formatMessage(
          planningTableHead.projectTableInitialDueDateToolTip
        ),
        headerRenderer: function () {
          return `<div style="display: flex; align-items: center;"><span style="margin-right: 5px;"> ${intl.formatMessage(
            planningTableHead.projectTableInitialDueDate
          )}</span><span>${iconRender()}</span</div>`;
        },
      },
      {
        type: "dueDateUtc",
        text: intl.formatMessage(planningTableHead.projectTableDueDate),
        width: bryntumColumnWidths.endDate,
        locked: true,
        field: "endDate",
        editor: {
          type: "datefield",
          format: "DD/MM/YYYY",
          picker: {
            disabledDates: value =>
              ["6", "0"].includes(DateHelper.format(value, "d")),
          },
          editable: false,
          listeners: {
            focusIn: ({ source }) => {
              if (!project.startDate || !source.value) return;

              const currentDate = new Date();
              currentDate.setDate(currentDate.getDate() + 1);

              const newDuration = moment(source.value).businessDiff(
                moment(project.startDate)
              );
              const tempDate = getBusinessDayAfterDuration(newDuration);

              const dateSelected =
                tempDate.format("d") === "1"
                  ? tempDate.subtract(2, "days")
                  : tempDate;

              source.min = dateSelected.toDate();
              source.picker.activeDate = dateSelected.toDate();
            },
            change: setTaskEndDate,
          },
        },
        EstimatedEndDatecheckBoxChange,
        wasRun,
        draggable: false,
        hidden: !showProjections,
        tooltip: intl.formatMessage(
          planningTableHead.projectTableEstimatedDueDateToolTip
        ),
        headerRenderer: function () {
          return `<div style="display: flex; align-items: center;"><span style="margin-right: 5px;"> ${intl.formatMessage(
            planningTableHead.projectTableDueDate
          )}</span><span>${iconRender()}</span</div>`;
        },
      },
      {
        type: "endDateUtc",
        text: intl.formatMessage(planningTableHead.projectTableEndDate),
        field: "endDateUtc",
        width: bryntumColumnWidths.endDateUtc,
        wasRun,
        locked: true,
        editor: false,
        draggable: false,
        hidden: false,
      },
    ],
    projectStatusDependentColumns: [
      {
        type: "name",
        text: intl.formatMessage(planningTableHead.projectTableName),
        field: "name",
        width: bryntumColumnWidths.name,
        editor: false,
        draggable: false,
        tooltipRenderer: ({ record }) => renderTooltipForProduct(record),
        hidden: false,
        renderer: ({ value, cellElement, record: { originalData } }) => {
          if (!originalData?.isUserTask) {
            cellElement.style.setProperty("font-weight", "500", "important");
            cellElement.style.setProperty("color", "#6e6e6e", "important");
          } else {
            cellElement.style.setProperty("font-weight", "300", "important");
            cellElement.style.setProperty("color", "#6e6e6e", "important");
          }
          return value;
        },
      },
      {
        type: "userResponsibility",
        text: intl.formatMessage(planningTableHead.projectTableResponsUser),
        width: bryntumColumnWidths.userResponsibility,
        locked: true,
        editor: false,
        field: "userResponsibility",
        draggable: false,
        hidden: false,
      },
      {
        type: "status",
        text: intl.formatMessage(planningTableHead.projectTableStatus),
        locked: true,
        editor: false,
        width: bryntumColumnWidths.status,
        field: "status",
        draggable: false,
        hidden: false,
      },
      {
        type: "date",
        text: intl.formatMessage(planningTableHead.projectTableStartDate),
        format: "DD/MM/YYYY",
        field: "startDate",
        width: bryntumColumnWidths.startDate,
        wasRun,
        editor: false,
        draggable: false,
        hidden: false,
      },
      {
        type: "timeTracked",
        text: intl.formatMessage(planningTableHead.projectTableTimetracked),
        field: "timeTracked",
        width: bryntumColumnWidths.timeTracked,
        locked: true,
        editor: false,
        draggable: false,
        hidden: false,
      },
      {
        uuid: "duration",
        type: "estimatedDuration",
        text: intl.formatMessage(planningTableHead.projectTableDuration),
        locked: false,
        width: bryntumColumnWidths.fullDuration,
        wasRun,
        draggable: false,
        hidden: !showProjections,
        editor: new DurationField({
          width: 200,
          decimalPrecision: 0,
          listeners: {
            change: e => {
              e.source.value = e.value.magnitude;
              e.source.eventRecord.originalData.estimatedDuration =
                e.value.magnitude;
              if (e?.source?.eventRecord?.originalData?.isTimerTask) {
                e.source.value = e.value.magnitude < 0 ? "0 day" : e.value;
              } else {
                e.source.value = e.value.magnitude < 1 ? "1 day" : e.value;
              }
            },
            input: e => {
              if (e.source.eventRecord.originalData.isTimerTask) {
                e.source.value = e.value.magnitude < 0 ? "0 day" : e.value;
              } else {
                e.source.value = e.value.magnitude < 1 ? "1 day" : e.value;
              }
            },
          },
        }),
      },
      {
        type: "initialDueDateUtcCreation",
        text: intl.formatMessage(planningTableHead.projectTableInitialDueDate),
        hidden: !showProjections,
        field: "initialDueDateUtc",
        width: bryntumColumnWidths.initialDueDateUtc,
        wasRun,
        locked: true,
        editor: false,
        draggable: false,
        tooltip: intl.formatMessage(
          planningTableHead.projectTableInitialDueDateToolTip
        ),
        headerRenderer: function () {
          return `<div style="display: flex; align-items: center;"><span style="margin-right: 5px;"> ${intl.formatMessage(
            planningTableHead.projectTableInitialDueDate
          )}</span><span>${iconRender()}</span</div>`;
        },
      },
      {
        type: "dueDateUtcCreation",
        text: intl.formatMessage(planningTableHead.projectTableDueDate),
        width: bryntumColumnWidths.endDate,
        locked: true,
        field: "endDate",
        editor: {
          type: "datefield",
          format: "DD/MM/YYYY",
          picker: {
            disabledDates: value =>
              ["6", "0"].includes(DateHelper.format(value, "d")),
          },
          editable: false,
          listeners: {
            focusIn: ({ source }) => {
              if (!project.startDate || !source.value) {
                return;
              }

              const currentDate = new Date();
              currentDate.setDate(currentDate.getDate() + 1);

              const newDuration = moment(source.value).businessDiff(
                moment(project.startDate)
              );
              const tempDate = getBusinessDayAfterDuration(newDuration);

              const dateSelected =
                tempDate.format("d") === "1"
                  ? tempDate.subtract(2, "days")
                  : tempDate;

              source.min = dateSelected.toDate();
              source.picker.activeDate = dateSelected.toDate();
            },
            change: setTaskEndDate,
          },
        },
        hidden: !showProjections,
        wasRun,
        tooltip: intl.formatMessage(
          planningTableHead.projectTableEstimatedDueDateToolTip
        ),
        headerRenderer: function () {
          return `<div style="display: flex; align-items: center;"><span style="margin-right: 5px;"> ${intl.formatMessage(
            planningTableHead.projectTableDueDate
          )}</span><span>${iconRender()}</span</div>`;
        },
        EstimatedEndDatecheckBoxChange,
      },
      {
        type: "endDateUtcCreation",
        text: intl.formatMessage(planningTableHead.projectTableEndDate),
        field: "endDateUtc",
        wasRun,
        width: bryntumColumnWidths.endDateUtc,
        locked: true,
        editor: false,
        draggable: false,
        hidden: false,
      },
    ],
  };
};

export const ganttSettings = (canEdit, intl) => ({
  readOnly: !canEdit,
  subGridConfigs: {
    locked: {
      width: "50%",
    },
    normal: {
      flex: 2,
    },
  },
  viewPreset: {
    id: "myId",
    base: "weekAndDay",
    timeResolution: {
      unit: "day",
      increment: 1,
    },
  },
  features: {
    taskDrag: false,
    rowReorder: false,
    projectLines: {
      showHeaderElements: true,
    },
    timeRanges: {
      currentDateFormat: "DD/MM/YY",
      showCurrentTimeLine: true,
    },
    taskMenu: false,
    taskEdit: false,
    nonWorkingTime: {
      disabled: false,
    },
    dependencies: {
      disabled: false,
      allowCreate: false,
    },
    cellTooltip: {
      textContent: true,
    },
    taskTooltip: {
      template(data) {
        const me = this;
        moment.locale(intl.locale);
        const { taskRecord, startDate, endDate } = data;
        const startClockHtml = moment(startDate).format("MMM Do, YYYY");
        const endClockHtml = moment(endDate).format("MMM Do, YYYY");
        const displayDuration = me.client.formatDuration(
          taskRecord.duration,
          me.decimalPrecision
        );

        return `
        ${
          taskRecord.name
            ? `<div class="b-gantt-task-title">${StringHelper.encodeHtml(
                taskRecord.name
              )}</div>`
            : ""
        }
        <table>
        <tr><td>${me.L("L{Start}")}:</td><td>${startClockHtml}</td></tr>
        ${
          taskRecord.milestone
            ? ""
            : `
            <tr><td>${me.L(
              "Estimated End date"
            )}:</td><td>${endClockHtml}</td></tr>
            <tr><td>${me.L(
              "Time Allocation"
            )}:</td><td class="b-right">${displayDuration} ${DateHelper.getLocalizedNameOfUnit(
                taskRecord.durationUnit,
                taskRecord.duration !== 1
              )}</td></tr>
        `
        }
        </table>                
    `;
      },
    },
  },
  minZoomLevel: 6,
  maxZoomLevel: 10,
  columnLines: false,
  calendarConfig: {
    weekends: [6, 0], // Saturday and Sunday are weekends
  },
});
