import {
  SET_GANTT_VIEW_DISPLAY_TASK,
  SET_GANTT_VIEW_EXPAND,
  SET_GANTT_VIEW_DISPLAY_CRITICAL_PATH,
  SET_GANTT_VIEW_IS_LOCKED_COLLAPSED,
  SET_GANTT_VIEW_IS_NORMAL_COLLAPSED,
  SET_GANTT_VIEW_SLIDER_POSITION,
  SET_GANTT_VIEW_DISPLAY_FUTURE_TASKS,
  SET_GANTT_VIEW_DISPLAY_PROJECTIONS,
  SET_GANNT_VIEW_FILTER_POPOVER,
  SET_GANTT_VIEW_WIDTH_OF_BRYTNUM_COLUMN,
} from "../../../constants";

export const setDisplayMyTasks = payload => {
  return {
    type: SET_GANTT_VIEW_DISPLAY_TASK,
    payload,
  };
};

export const setGanttPopoverFilter = payload => {
  return {
    type: SET_GANNT_VIEW_FILTER_POPOVER,
    payload,
  };
};

export const setExpanded = payload => {
  return {
    type: SET_GANTT_VIEW_EXPAND,
    payload,
  };
};

export const setShowCriticalPath = payload => {
  return {
    type: SET_GANTT_VIEW_DISPLAY_CRITICAL_PATH,
    payload,
  };
};

export const setShowFutureTasks = payload => {
  return {
    type: SET_GANTT_VIEW_DISPLAY_FUTURE_TASKS,
    payload,
  };
};

export const setShowProjections = payload => {
  return {
    type: SET_GANTT_VIEW_DISPLAY_PROJECTIONS,
    payload,
  };
};

export const setIsLockedCollapsed = payload => {
  return {
    type: SET_GANTT_VIEW_IS_LOCKED_COLLAPSED,
    payload,
  };
};

export const setIsNormalCollapsed = payload => {
  return {
    type: SET_GANTT_VIEW_IS_NORMAL_COLLAPSED,
    payload,
  };
};

export const setSliderPosition = payload => {
  return {
    type: SET_GANTT_VIEW_SLIDER_POSITION,
    payload,
  };
};
export const setBryntumColumnSize = payload => {
  return {
    type: SET_GANTT_VIEW_WIDTH_OF_BRYTNUM_COLUMN,
    payload,
  };
};
