import { defineMessages } from "react-intl";

export const teamsRetailPlaceholder = defineMessages({
  placeholder: {
    id: "projects.components.teamsRetail.placeholder",
    defaultMessage: "Please enter the name of your {name}",
  },
  notInvited: {
    id: "projects.components.teamsRetail.notInvited",
    defaultMessage: "Not invited",
  },
});

export const teamsSupplierDefineMessages = defineMessages({
  nameOfSupplier: {
    id: "projects.components.teamsSupplier.nameOfSupplier",
    defaultMessage: "Name of your supplier",
  },
  placeholder: {
    id: "projects.components.teamsSupplier.placeholder",
    defaultMessage: "Please write the name of your {name}",
  },
  productsLinked: {
    id: "projects.components.teamsSupplier.productsLinked",
    defaultMessage:
      "{count} {count, plural, one {product linked} other {products linked}}",
  },
});

export const createProductsMessages = defineMessages({
  header: {
    id: "projects.createProducts.header",
    defaultMessage: "Create your new product(s)",
  },
  createandAssociate: {
    id: "projects.createProducts.create.associate",
    defaultMessage: "Create & associate",
  },
  totalProducts: {
    id: "projects.createProducts.totalProducts",
    defaultMessage: "How many products do you want to create?",
  },
  para1: {
    id: "projects.createProducts.para1",
    defaultMessage: "Add as many new products as you need to your project.",
  },
  para2: {
    id: "projects.createProducts.para2",
    defaultMessage:
      "Don't worry, you will be able to edit the details of each product later.",
  },
  createProducts: {
    id: "projects.createProducts.createProducts",
    defaultMessage: "Create products",
  },
  addNewProduct: {
    id: "projects.createProducts.addNewProduct",
    defaultMessage: "Add new product",
  },
  defaultProductName: {
    id: "projects.createProducts.defaultProductName",
    defaultMessage: "Default product name",
  },
  create: {
    id: "projects.createProducts.create",
    defaultMessage: "Create",
  },
  btnDuplicate: {
    id: "projects.createProducts.btnDuplicate",
    defaultMessage: "Duplicate",
  },
  btnDelete: {
    id: "projects.createProducts.btnDelete",
    defaultMessage: "Delete",
  },
  labelProdName: {
    id: "projects.createProducts.labelProdName",
    defaultMessage: "Product name (mandatory)",
  },
  prodNamePlaceholder: {
    id: "projects.createProducts.prodNamePlaceholder",
    defaultMessage: "Enter product name",
  },
  gtinPlaceholder: {
    id: "projects.createProducts.gtinPlaceholder",
    defaultMessage: "Enter the GTIN",
  },
  labelBrand: {
    id: "projects.createProducts.labelBrand",
    defaultMessage: "Brand",
  },
  selectBrandPlaceholder: {
    id: "projects.createProducts.selectBrandPlaceholder",
    defaultMessage: "Choose a product brand",
  },
  labelNetContent: {
    id: "projects.createProducts.labelNetContent",
    defaultMessage: "Net content",
  },
  weightPlaceholder: {
    id: "projects.createProducts.weightPlaceholder",
    defaultMessage: "Weight",
  },
  selectUnitPlaceholder: {
    id: "projects.createProducts.selectUnitPlaceholder",
    defaultMessage: "unit",
  },
  selectCategoryPlaceholder: {
    id: "projects.createProducts.selectCategoryPlaceholder",
    defaultMessage: "Choose a category",
  },
  labelCategory: {
    id: "projects.createProducts.labelCategory",
    defaultMessage: "Category",
  },
  labelSuppliers: {
    id: "projects.createProducts.labelSuppliers",
    defaultMessage: "Supplier name",
  },
  suppliersPlaceholder: {
    id: "projects.createProducts.suppliersPlaceholder",
    defaultMessage: "Enter a supplier name",
  },
  gtinExistsError: {
    id: "projects.createProducts.gtinExistsError",
    defaultMessage: "This GTIN is already defined for another trade item",
  },
  nameError: {
    id: "projects.creation.helpers.warnName",
    defaultMessage: "You must specify a name",
  },
  validGtin: {
    id: "projects.createProducts.validGtin",
    defaultMessage: "Enter a valid GTIN",
  },
  duplicatedName: {
    id: "projects.createProducts.duplicatedName",
    defaultMessage: "Duplicated product name",
  },
  unitError: {
    id: "projects.createProducts.unitError",
    defaultMessage: "Enter both values",
  },
  error: {
    id: "components.error",
    defaultMessage: "Error",
  },
  maxProductsError: {
    id: "projects.createProducts.maxProductsError",
    defaultMessage: "A maximum of 50 products can be created at one time",
  },
  invalidGtinError: {
    id: "projects.createProducts.invalidGtinError",
    defaultMessage: "You are trying to save a product with an invalid GTIN",
  },
});

export const stepperName = defineMessages({
  definition: {
    id: "projects.creation.helpers.updateStepper.definition",
    defaultMessage: "definition",
  },
  products: {
    id: "projects.creation.helpers.updateStepper.products",
    defaultMessage: "products",
  },
  planning: {
    id: "projects.creation.helpers.updateStepper.planning",
    defaultMessage: "planning",
  },
  team: {
    id: "projects.creation.helpers.updateStepper.team",
    defaultMessage: "team",
  },
  summary: {
    id: "projects.creation.helpers.updateStepper.summary",
    defaultMessage: "summary",
  },
});

export const warningTranslated = defineMessages({
  warnName: {
    id: "projects.creation.helpers.warnName",
    defaultMessage: "You must specify a name",
  },
  warnType: {
    id: "projects.creation.helpers.warnType",
    defaultMessage: "You must specify a type",
  },
  warnDate: {
    id: "projects.creation.helpers.warnDate",
    defaultMessage: "You must specify a start date",
  },
  warnProjectFolderName: {
    id: "projects.creation.helpers.warnProjectFolderName",
    defaultMessage: "You must specify a name",
  },
  warnProjectFolderType: {
    id: "projects.creation.helpers.warnProjectFolderType",
    defaultMessage: "You must specify a type",
  },
  warnProjectFolderDate: {
    id: "projects.creation.helpers.warnProjectFolderDate",
    defaultMessage: "You must specify a start date",
  },
});

export const alertTrad = defineMessages({
  successSavingMessage: {
    id: "projects.creation.alertDraft.successSavingMessage",
    defaultMessage: "Confirmation",
  },
  successSavingDescription: {
    id: "projects.creation.alertDraft.successSavingDescription",
    defaultMessage: "Your project was successfully saved as a draft",
  },
});

export const titleCreation = defineMessages({
  id: "projects.creation.title",
  defaultMessage: "Project Creation",
});

export const timelineSteps = defineMessages({
  today: {
    id: "projects.creation.timeline.today",
    defaultMessage: "TODAY",
  },
  start: {
    id: "projects.creation.timeline.start",
    defaultMessage: "START",
  },
  end: {
    id: "projects.creation.timeline.end",
    defaultMessage: "END",
  },
});

export const participantsTrans = defineMessages({
  numberOfParticipants: {
    id: "projects.creation.summary.participants.number",
    defaultMessage: "PARTICIPANT{s} AT MY COMPANY",
  },
  noUser: {
    id: "projects.creation.summary.participants.noUser",
    defaultMessage: "No user assigned",
  },
});

export const suppliersTrans = defineMessages({
  numberOfSuppliers: {
    id: "projects.creation.summary.supplier.number",
    defaultMessage: "SUPPLIER{s}",
  },
  noUser: {
    id: "projects.creation.summary.supplier.noUser",
    defaultMessage: "No user assigned",
  },
  productLinked: {
    id: "projects.creation.summary.supplier.productLinked",
    defaultMessage: "Number of products linked",
  },
});

export const summaryTrans = defineMessages({
  sectionName: {
    id: "projects.creation.summary.name",
    defaultMessage: "Name: ",
  },
  sectionType: {
    id: "projects.creation.summary.type",
    defaultMessage: "Type: ",
  },
  sectionStartDate: {
    id: "projects.creation.summary.startDate",
    defaultMessage: "Start date: ",
  },
  sectionNumProducts: {
    id: "projects.creation.summary.numProducts",
    defaultMessage: "Number of products: ",
  },
  sectionTitleDef: {
    id: "projects.creation.summary.sectionTitleDef",
    defaultMessage: "Project definition",
  },
  sectionSubDef: {
    id: "projects.creation.summary.sectionSubDef",
    defaultMessage: "Summary of your project",
  },
  sectionTitleTimeline: {
    id: "projects.creation.summary.sectionTitleTimeline",
    defaultMessage: "Timeline",
  },
  sectionSubTimeline: {
    id: "projects.creation.summary.sectionSubTimeline",
    defaultMessage: "Have an overview of your project",
  },
  sectionTitleMembersTimeline: {
    id: "projects.creation.summary.sectionTitleMembersTimeline",
    defaultMessage: "Participant(s)",
  },
  sectionSubMembersTimeline: {
    id: "projects.creation.summary.sectionSubMembersTimeline",
    defaultMessage: "List of members",
  },
  sectionSubStatus: {
    id: "projects.creation.summary.status",
    defaultMessage: "Status: ",
  },
});

export const translatedButtons = defineMessages({
  noProducts: {
    id: "projects.creation.components.buttons.noProducts",
    defaultMessage: "No products selected.",
  },
  launch: {
    id: "projects.creation.components.buttons.launch",
    defaultMessage: "LAUNCH",
  },
  next: {
    id: "projects.creation.components.buttons.next",
    defaultMessage: "Next",
  },
  skip: {
    id: "projects.creation.components.buttons.skip",
    defaultMessage: "Skip",
  },
  saving: {
    id: "projects.creation.components.buttons.saving",
    defaultMessage: "Saving...",
  },
  saveAsDraft: {
    id: "projects.creation.components.buttons.saveAsDraft",
    defaultMessage: "Save",
  },
  goToProject: {
    id: "projects.creation.components.buttons.goToProject",
    defaultMessage: "Go to project",
  },
  saveAndGo: {
    id: "projects.creation.components.buttons.saveAndGo",
    defaultMessage: "Save & Go",
  },
  goWithoutSaving: {
    id: "projects.creation.components.buttons.goWithoutSaving",
    defaultMessage: "Go without saving",
  },
  warningMessage: {
    id: "projects.creation.components.buttons.warningMessage",
    defaultMessage:
      "You have unsaved changes on this step. Do you want to save them before going to the project detail ?",
  },
  cancelCreation: {
    id: "projects.creation.components.buttons.cancelCreation",
    defaultMessage: "Cancel creation",
  },
});

export const btnSave = defineMessages({
  saving: {
    id: "projects.creation.components.popup.saving",
    defaultMessage: " SAVING...",
  },
  save: {
    id: "projects.creation.components.popup.save",
    defaultMessage: "YES",
  },
});

export const columnTitle = defineMessages({
  titleProductName: {
    id: "projects.creation.components.table.productName",
    defaultMessage: "Product name",
  },
  titleBrand: {
    id: "projects.creation.components.table.productBrand",
    defaultMessage: "Product brand",
  },
  titleCategory: {
    id: "projects.creation.components.table.catagory",
    defaultMessage: "Category",
  },
  titleEmptyTable: {
    id: "projects.creation.components.table.noData",
    defaultMessage: "No product has been added",
  },
  selected: {
    id: "projects.creation.components.table.selected",
    defaultMessage: "selected",
  },
  supplierName: {
    id: "projects.creation.components.table.supplierName",
    defaultMessage: "Supplier name",
  },
  packagingSystemName: {
    id: "projects.creation.components.table.packagingSystemName",
    defaultMessage: "Packaging system",
  },
  status: {
    id: "projects.creation.components.table.status",
    defaultMessage: "Status",
  },
  labelDetachPackaging: {
    id: "projects.creation.components.table.labelDetachPackaging",
    defaultMessage: "Detach Packaging System",
  },
  printable: {
    id: "projects.creation.components.table.printable",
    defaultMessage: "Printable",
  },
  printableYes: {
    id: "projects.creation.components.table.printableYes",
    defaultMessage: "PrintableYes",
  },
  printableNo: {
    id: "projects.creation.components.table.printableNo",
    defaultMessage: "PrintableNo",
  },
});

export const titleAndLabel = defineMessages({
  sectionTitleDef: {
    id: "projects.creation.definition.sectionTitleDef",
    defaultMessage: "Projects definition",
  },
  sectionSubtitleDef: {
    id: "projects.creation.definition.subtitleDef",
    defaultMessage: "Define your project",
  },
  labelName: {
    id: "projects.creation.definition.labelName",
    defaultMessage: "Project name",
  },
  labelCodeName: {
    id: "projects.creation.definition.labelCodeName",
    defaultMessage: "Project Code",
  },
  labelType: {
    id: "projects.creation.definition.labelType",
    defaultMessage: "Project type",
  },
  selectTypePlaceholder: {
    id: "projects.creation.definition.selectPlaceholder",
    defaultMessage: "Choose a project type",
  },
  sectionTitleProducts: {
    id: "projects.creation.definition.sectionTitleProducts",
    defaultMessage: "Project products",
  },
  sectionSubtitleProducts: {
    id: "projects.creation.definition.subtitleProducts",
    defaultMessage: "Select the project's products",
  },
  projectNamePlaceholder: {
    id: "projects.creation.definition.projectNamePlaceholder",
    defaultMessage: "Enter here the name of your project",
  },
  projectCodePlaceholder: {
    id: "projects.creation.definition.projectCodeNamePlaceholder",
    defaultMessage: "Enter here the Code of your project",
  },
});

export const selectProductsTrad = defineMessages({
  sectionTitleProducts: {
    id: "projects.creation.products.sectionTitleProducts",
    defaultMessage: "Project products",
  },
  sectionSubtitleProducts: {
    id: "projects.creation.products.subtitleProducts",
    defaultMessage: "Select the project's products",
  },
});

export const planningSectionTitle = defineMessages({
  title: {
    id: "projects.creation.planning.title",
    defaultMessage: "List of tasks and milestones of your project",
  },
  subtitle: {
    id: "projects.creation.planning.subtitle",
    defaultMessage: "Manage and edit the flow of your project",
  },
});

export const showOrHide = defineMessages({
  show: {
    id: "projects.creation.planning.components.bryntumTableGantt.show",
    defaultMessage: "show",
  },
  hide: {
    id: "projects.creation.planning.components.bryntumTableGantt.hide",
    defaultMessage: "hide",
  },
  gantt: {
    id: "projects.creation.planning.components.bryntumTableGantt.gantt",
    defaultMessage: "Gantt chart view",
  },
  list: {
    id: "projects.creation.planning.components.bryntumTableGantt.list",
    defaultMessage: "List view",
  },
});

export const retailSubtitle = defineMessages({
  id: "projects.creation.team.components.retailParticipants.subtitle",
  defaultMessage: "Start adding your participants",
});

export const suppliersSectionDefineMessages = defineMessages({
  title: {
    id: "projects.creation.team.components.suppliersSection.title",
    defaultMessage: "Suppliers",
  },
  subTitle: {
    id: "projects.creation.team.components.suppliersSection.subtitle",
    defaultMessage: "Start adding your suppliers",
  },
  subTitle2: {
    id: "projects.creation.team.components.suppliersSection.subtitle2",
    defaultMessage: "Name of your suppliers",
  },
});

export const dashboardTitle = defineMessages({
  id: "projects.indexProjects.title",
  defaultMessage: "Dashboard",
});

export const projectFolderTabName = defineMessages({
  defintion: {
    id: "projects.projectFolder.Definition",
    defaultMessage: "Definition",
  },
  products: {
    id: "projects.projectFolder.products",
    defaultMessage: "Products",
  },
  tasks: {
    id: "projects.projectFolder.tasks",
    defaultMessage: "Tasks",
  },
  team: {
    id: "projects.projectFolder.team",
    defaultMessage: "Team",
  },
  document: {
    id: "projects.projectFolder.document",
    defaultMessage: "Document",
  },
});

export const showHide = defineMessages({
  show: {
    id: "projects.projectFolder.bryntumContent.show",
    defaultMessage: "show",
  },
  hide: {
    id: "projects.projectFolder.bryntumContent.hide",
    defaultMessage: "hide",
  },
  gantt: {
    id: "projects.projectFolder.bryntumContent.gantt",
    defaultMessage: "Gantt chart view",
  },
  list: {
    id: "projects.projectFolder.bryntumContent.list",
    defaultMessage: "List view",
  },
});

export const launchTranslate = defineMessages({
  error: {
    id: "components.error",
    defaultMessage: "Error",
  },
  noProductSelected: {
    id: "projects.projectFolder.buttonProjectFolder.noProductSelected",
    defaultMessage: "No product selected.",
  },
  pastStartDate: {
    id: "projects.projectFolder.buttonProjectFolder.pastStartDate",
    defaultMessage: "The start date is in the past.",
  },
  startDate: {
    id: "projects.projectFolder.buttonProjectFolder.startDate",
    defaultMessage: "No date selected.",
  },
  saving: {
    id: "projects.projectFolder.buttonProjectFolder.saving",
    defaultMessage: "Saving...",
  },
  save: {
    id: "projects.projectFolder.buttonProjectFolder.save",
    defaultMessage: "Save",
  },
  launch: {
    id: "projects.projectFolder.buttonProjectFolder.launch",
    defaultMessage: "LAUNCH",
  },
  cancel: {
    id: "projects.projectFolder.buttonProjectFolder.cancel",
    defaultMessage: "CANCEL",
  },
  associatePackaging: {
    id: "projects.projectFolder.buttonProjectFolder.associatePackaging",
    defaultMessage: "Associate packaging",
  },
});

export const defTabLabel = defineMessages({
  titleName: {
    id: "projects.projectFolder.defTabContent.name",
    defaultMessage: "Project Name:",
  },
  titleCodeName: {
    id: "projects.projectFolder.defTabContent.Codename",
    defaultMessage: "Project Code:",
  },
  titleStatus: {
    id: "projects.projectFolder.defTabContent.status",
    defaultMessage: "Status:",
  },
  titleType: {
    id: "projects.projectFolder.defTabContent.type",
    defaultMessage: "Project Type:",
  },
  selectTypePlaceholder: {
    id: "projects.projectFolder.definition.selectPlaceholder",
    defaultMessage: "Choose a project type",
  },
});

export const alert = defineMessages({
  draft: {
    id: "projects.projectFolder.projectHeader.alertDraft",
    defaultMessage: "Your project was successfully saved",
  },
  activated: {
    id: "projects.projectFolder.projectHeader.alertActivated",
    defaultMessage: "Your project was successfully activated",
  },
  default: {
    id: "projects.projectFolder.projectHeader.alertDefault",
    defaultMessage: "Your project was saved successfully",
  },
  cantCreateAndLaunchTitle: {
    id: "projects.projectFolder.cantCreateAndLaunchTitle",
    defaultMessage:
      "Sorry, you don't have the permissions to launch or edit this project.",
  },
  cantCreateAndLaunchContent: {
    id: "projects.projectFolder.cantCreateAndLaunchContent",
    defaultMessage: "Please contact your administrator for more information.",
  },
});

export const intParticipants = defineMessages({
  internalTeam: {
    id: "projects.projectFolder.teamContent.internalParticipant",
    defaultMessage: "Internal Team",
  },
  selectTeamBtn: {
    id: "projects.projectFolder.teamContent.selectTeamBtn",
    defaultMessage: "Select a team",
  },
});

export const projectOwners = defineMessages({
  title: {
    id: "projects.projectFolder.teamContent.projectOwners",
    defaultMessage: "Project Owners",
  },
  placeholder: {
    id: "projects.projectFolder.teamContent.placeholder",
    defaultMessage: "Select a user",
  },
  projectCreator: {
    id: "projects.projectFolder.teamContent.projectCreator",
    defaultMessage: "Project Creator",
  },
  addButton: {
    id: "projects.projectFolder.teamContent.addButton",
    defaultMessage: "Add",
  },
  additionalProjectOwners: {
    id: "projects.projectFolder.teamContent.additionalProjectOwners",
    defaultMessage: "Additional Project Owners",
  },
});

export const projectHeader = defineMessages({
  customerName: {
    id: "projects.projectFolder.projectHeader.customerName",
    defaultMessage: "Customer name",
  },
  projectId: {
    id: "projects.projectFolder.projectHeader.projectId",
    defaultMessage: "Project ID ",
  },
  step: {
    id: "projects.projectFolder.projectHeader.step",
    defaultMessage: "Step ",
  },
  startDate: {
    id: "projects.projectFolder.projectHeader.startDate",
    defaultMessage: "Start date",
  },
  tooltipStartandEndDate: {
    id: "projects.projectFolder.projectHeader.tooltipStartandEndDate",
    defaultMessage:
      "These 2 fields are automatically calculated due to fixed tasks",
  },
  actualEndDate: {
    id: "projects.projectFolder.projectHeader.ActualEndDate",
    defaultMessage: "Actual end date",
  },
  projectedEndDate: {
    id: "projects.projectFolder.projectHeader.projectedEndDate",
    defaultMessage: "Estimated end date",
  },
  baselineEndDate: {
    id: "projects.projectFolder.projectHeader.baselineEndDate",
    defaultMessage: "Initial end date",
  },
  projectCode: {
    id: "projects.projectFolder.projectHeader.projectCode",
    defaultMessage: "Project code",
  },
  onHoldProject: {
    id: "projects.projectFolder.projectHeader.onHoldProject",
    defaultMessage: "Set project on hold",
  },
  resumeProject: {
    id: "projects.projectFolder.projectHeader.resumeProject",
    defaultMessage: "Resume project",
  },
  newPlannedEndDate: {
    id: "projects.projectFolder.projectHeader.newPlannedEndDate",
    defaultMessage: "New Projected End Date",
  },
  actions: {
    id: "projects.projectFolder.projectHeader.actions",
    defaultMessage: "Actions",
  },
  archiveTheProject: {
    id: "projects.projectFolder.projectHeader.archiveTheProject",
    defaultMessage: "Archive the project",
  },
  tooltipTitleArchive: {
    id: "projects.projectFolder.projectHeader.tooltipTitleArchive",
    defaultMessage: "You are about to archive a project. Please confirm.",
  },
  tooltipTitleDelete: {
    id: "projects.projectFolder.projectHeader.tooltipTitleDelete",
    defaultMessage: "You are about to delete a project. Please confirm.",
  },
  confirm: {
    id: "projects.projectFolder.projectHeader.confirm",
    defineMessages: "Confirm",
  },
  cancel: {
    id: "projects.projectFolder.projectHeader.cancel",
    defineMessages: "Cancel",
  },
  delete: {
    id: "projects.projectFolder.projectHeader.delete",
    defaultMessage: "Delete",
  },
  archivedSuccessfullyMessage: {
    id: "projects.projectFolder.projectHeader.archivedMessage",
    defaultMessage: "The selected project has been archived.",
  },
  deletedSuccessfullyMessage: {
    id: "projects.projectFolder.projectHeader.deletedMessage",
    defaultMessage: "The selected project has been deleted.",
  },
  putOnHoldSuccessfullyMessage: {
    id: "projects.projectFolder.projectHeader.putOnHoldMessage",
    defaultMessage: "The selected project has been put on hold.",
  },
  resumedSuccessfullyMessage: {
    id: "projects.projectFolder.projectHeader.resumedMessage",
    defaultMessage: "The selected project has been resumed.",
  },
});

export const projectDeleteProduct = defineMessages({
  title: {
    id: "projects.projectFolder.components.popupDelete.title",
    defaultMessage: "remove a product",
  },
  text1: {
    id: "projects.projectFolder.components.popupDelete.text1",
    defaultMessage: "You are about to remove a product from the project.",
  },
  text2: {
    id: "projects.projectFolder.components.popupDelete.text2",
    defaultMessage: "All the tasks related to this product will be lost.",
  },
  question: {
    id: "projects.projectFolder.components.popupDelete.question",
    defaultMessage: "Do you confirm the removal ?",
  },
  cancel: {
    id: "projects.projectFolder.components.popupDelete.btnCancel",
    defaultMessage: "CANCEL",
  },
  confirm: {
    id: "projects.projectFolder.components.popupDelete.btnConfirm",
    defaultMessage: "YES",
  },
});
export const projectAddManufItem = defineMessages({
  labelSupplierName: {
    id: "projects.projectFolder.components.popUpAddSupplier.select.labelSupplierName",
    defaultMessage: "Supplier name",
  },
  subheader2: {
    id: "projects.projectFolder.components.popUpAddSupplier.select.subheader2",
    defaultMessage: "New suppliers",
  },
  btnAddSupplier: {
    id: "projects.projectFolder.components.popUpAddSupplier.select.btnAddSupplier",
    defaultMessage: "Add a supplier",
  },
  modalTitle: {
    id: "projects.projectFolder.components.popUpAddSupplier.select.modalTitle",
    defaultMessage: "Select supplier(s) for ",
  },
  subheader1: {
    id: "projects.projectFolder.components.popUpAddSupplier.select.subheader1",
    defaultMessage: "Suppliers already in the project for this item",
  },
  alertMessage: {
    id: "projects.projectFolder.components.popUpAddSupplier.select.alertMessage",
    defaultMessage:
      "A new product with this supplier will be created in your database",
  },
  btnValidate: {
    id: "projects.projectFolder.components.popUpAddSupplier.select.btnValidate",
    defaultMessage: "Validate",
  },
  btnCreateCompany: {
    id: "projects.projectFolder.components.popUpAddSupplier.select.btnCreateCompany",
    defaultMessage: "Create and Associate",
  },
  labelSupplierCount: {
    id: "projects.projectFolder.components.popUpAddSupplier.select.labelSupplierCount",
    defaultMessage: "Supplier",
  },
  errorMessage: {
    id: "projects.projectFolder.components.popUpAddSupplier.select.errorMessage",
    defaultMessage: "Select a supplier to validate",
  },
  errorCompanyMessage: {
    id: "projects.projectFolder.components.popUpAddSupplier.select.errorCompanyMessage",
    defaultMessage:
      "Cannot associate this company to a product as it is not a supplier",
  },
  errorTitle: {
    id: "projects.projectFolder.components.popUpAddSupplier.select.errorTitle",
    defaultMessage: "Error",
  },
});

export const teamsList = defineMessages({
  categoryLabel: {
    id: "projects.projectFolder.teamsList.categoryLabel",
    defaultMessage: "Category",
  },
  brandLabel: {
    id: "projects.projectFolder.teamsList.brandLabel",
    defaultMessage: "Product brand",
  },
  members: {
    id: "projects.projectFolder.teamsList.members",
    defaultMessage: "Member{s}",
  },
  shared: {
    id: "projects.projectFolder.teamsList.shared",
    defaultMessage: "Shared",
  },
});

export const selectTeam = defineMessages({
  title: {
    id: "projects.projectFolder.selectTeam.title",
    defaultMessage: "SELECT A TEAM",
  },
  subtitle: {
    id: "projects.projectFolder.selectTeam.subtitle",
    defaultMessage: "Select a team from the list",
  },
  searchPlaceholder: {
    id: "projects.projectFolder.selectTeam.searchPlaceholder",
    defaultMessage: "Type the name of a team, an user, a brand, a category,...",
  },
  search: {
    id: "projects.projectFolder.selectTeam.search",
    defaultMessage: "Search",
  },
  selectBtn: {
    id: "projects.projectFolder.selectTeam.selectBtn",
    defaultMessage: "Select",
  },
  cancelBtn: {
    id: "projects.projectFolder.selectTeam.cancelBtn",
    defaultMessage: "Cancel",
  },
});

export const statusDataProject = defineMessages({
  modified: {
    id: "projects.projectFolder.statusDataProject.modified",
    defaultMessage: "modified",
  },
  saved: {
    id: "projects.projectFolder.statusDataProject.saved",
    defaultMessage: "saved",
  },
  savedError: {
    id: "projects.projectFolder.statusDataProject.savedError",
    defaultMessage: "An error occured while saving",
  },
  saving: {
    id: "projects.projectFolder.statusDataProject.saving",
    defaultMessage: "saving",
  },
});

export const popDataNotSaved = defineMessages({
  dataNotSaved: {
    id: "projects.projectFolder.popDataNotSaved.dataNotSaved",
    defaultMessage: "DATA NOT SAVED",
  },
  description1: {
    id: "projects.projectFolder.popDataNotSaved.description1",
    defaultMessage: "Some changes have not been saved.",
  },
  description2: {
    id: "projects.projectFolder.popDataNotSaved.description2",
    defaultMessage: "If you leave the page the data changes may be lost.",
  },
  question: {
    id: "projects.projectFolder.popDataNotSaved.question",
    defaultMessage: "Do you want to leave without saving ?",
  },
  yes: {
    id: "projects.projectFolder.popDataNotSaved.yes",
    defaultMessage: "YES",
  },
  cancel: {
    id: "projects.projectFolder.popDataNotSaved.cancel",
    defaultMessage: "CANCEL",
  },
});

export const ganttToolMenu = defineMessages({
  expand: {
    id: "projects.projectFolder.ganttToolMenu.expand",
    defaultMessage: "Expand all",
  },
  myTasks: {
    id: "projects.projectFolder.ganttToolMenu.myTasks",
    defaultMessage: "Display my tasks only",
  },
  criticalPathShow: {
    id: "projects.projectFolder.ganttToolMenu.criticalPathShow",
    defaultMessage: "Show critical path",
  },
  futureTasksShow: {
    id: "projects.projectFolder.ganttToolMenu.futureTasksShow",
    defaultMessage: "Show future tasks",
  },
  showProjections: {
    id: "projects.projectFolder.ganttToolMenu.showProjections",
    defaultMessage: "Show Projections",
  },
  calculated: {
    id: "projects.projectFolder.ganttToolMenu.projectionsCalculated",
    defaultMessage: "Calculated at the start",
  },
  late: {
    id: "projects.projectFolder.ganttToolMenu.projectionsLate",
    defaultMessage: "Late",
  },
  onTrack: {
    id: "projects.projectFolder.ganttToolMenu.projectionsOnTrack",
    defaultMessage: "On Track",
  },
});
export const explorerComponent = defineMessages({
  explorerTitle: {
    id: "projects.components.explorerComponent.explorerTitle",
    defaultMessage: "Explorer",
  },
  projectTitle: {
    id: "projects.components.explorerComponent.projectTitle",
    defaultMessage: "Project Overview",
  },
});

export const hardDateComponent = defineMessages({
  fixableTask: {
    id: "constants.components.bryntumTableGantt.fixableTask",
    defaultMessage:
      "To configure your schedule, opt for simple management by interacting only with the project dates or define key points and set them to have clear and precise guidelines.",
  },
  startDateInThePast: {
    id: "constants.components.bryntumTableGantt.startDateInThePast",
    defaultMessage:
      "The project started in the past, regarding the date you defined. Please validate tasks to match project deadlines",
  },
});
