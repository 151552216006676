export type StorageType = Readonly<{
  set: (value: object | string) => void;
  get: () => any;
  remove: () => void;
}>;

const buildStorage =
  (storage: Storage) =>
  (key: string): StorageType =>
    Object.freeze({
      set: (value: object | string) => {
        if (typeof value === "object") {
          const result = JSON.stringify(value);
          storage?.setItem(key, result);
        } else {
          storage?.setItem(key, value);
        }
      },
      get: () => {
        const result = storage?.getItem(key);
        try {
          return JSON.parse(result);
        } catch (error) {
          return result;
        }
      },
      remove: () => {
        storage?.removeItem(key);
      },
    });

export const buildSessionStorage = buildStorage(sessionStorage);
export const buildLocalStorage = buildStorage(localStorage);

export const SessionKeys = Object.freeze({
  BACK_TO_APP: "back-to-app",
});
