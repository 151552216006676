import { useMemo } from "react";
import { useLocation } from "react-router";

export default function useRouteSearchParams<T extends object>() {
  const { search } = useLocation();
  const searchParamsObj = useMemo<Partial<T>>(() => {
    const params = new URLSearchParams(search);
    const result = {};

    params.forEach((value, key) => {
      result[key] = value;
    });

    return result;
  }, [search]);

  return searchParamsObj;
}
