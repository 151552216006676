import React, { lazy, Suspense } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { Errors } from "routes/Errors";
import { useSelector } from "react-redux";
import { MainLayout } from "layouts/Main";
import Creation from "./Projects/Creation";
import {
  selectPermissions,
  selectHasUserAccessToApplication,
} from "common/selectors/users.selectors";
import { AuthorizedRoute } from "components/AuthorizedRoute";
import { LoadingPage } from "@trace-one/react-components";
import { ProjectAppMainContextProvider } from "contexts/ProjectAppMainContext";
import AssociatePackagingSystem from "./Projects/ProjectFolder/tabsComponents/products/associatePackagingSystem/AssociatePackagingSystem";
import CreatePackagingSystemForm from "components/FormSchema/widgets/PackagingSystemWidget/createPackagingSystem/CreatePackagingSystemForm";
import EditPackagingSystem from "components/FormSchema/widgets/PackagingSystemWidget/createPackagingSystem/EditPackagingSystem";

const ProjectFolder = lazy(() => import("routes/Projects/ProjectFolder"));
const Tasks = lazy(() => import("routes/Tasks/TasksList"));
const Task = lazy(() =>
  import("routes/Projects/ProjectFolder/tabsComponents/tasks/Task/Task")
);
const ProjectsList = lazy(() => import("routes/ProjectsList"));
const Administration = lazy(() => import("./Administration/Administration"));
const Home = lazy(() => import("routes/Home"));
const PocPDFTron = lazy(() => import("routes/PocPDFTron"));

const Routes = () => {
  const { projectsWrite } = useSelector(selectPermissions);
  const hasAccessToApplication = useSelector(selectHasUserAccessToApplication);
  return (
    <ProjectAppMainContextProvider>
      <Router>
        <MainLayout>
          <Suspense
            fallback={
              <LoadingPage
                title="LOADING..."
                subtitle="Please wait, your page will be displayed"
              />
            }
          >
            <Switch>
              <AuthorizedRoute
                isActive={hasAccessToApplication}
                exact
                path={[
                  "/tasks/tasks-list",
                  "/tasks/tasks-list/:id",
                  "/tasks/tasks-list/:id/:fileId/:versionId",
                  "/tasks/calendar",
                  "/tasks/calendar/:id",
                ]}
                component={Tasks}
              />
              <AuthorizedRoute
                isActive={hasAccessToApplication}
                exact
                path="/projects/projectfolder/:id/products/:associatedProductId/packagingsystem"
                component={AssociatePackagingSystem}
              />
              <AuthorizedRoute
                isActive={hasAccessToApplication}
                exact
                path="/projects/projectfolder/:id/tasks/:taskId/:manufItemId/packagingSystemCreation"
                component={CreatePackagingSystemForm}
              />
              <AuthorizedRoute
                isActive={hasAccessToApplication}
                exact
                path="/projects/projectfolder/:id/tasks/:taskId/:manufItemId/packagingSystemCreation/:packagingSystemId"
                component={EditPackagingSystem}
              />
              <AuthorizedRoute
                isActive={hasAccessToApplication}
                exact
                path="/projects/projects-list"
                component={ProjectsList}
              />
              <AuthorizedRoute
                exact
                isActive={hasAccessToApplication}
                path={[
                  "/projects/creation/:step",
                  "/projects/creation/:step/*",
                ]}
              >
                {projectsWrite ? <Creation /> : <Redirect to="/" />}
              </AuthorizedRoute>
              <AuthorizedRoute
                isActive={hasAccessToApplication}
                exact
                path={[
                  "/projects/projectfolder/:id/tasks/:taskId/upcoming-task-page",
                ]}
                component={Task}
              />
              <AuthorizedRoute
                isActive={hasAccessToApplication}
                exact
                path={[
                  "/projects/projectfolder/:id",
                  "/projects/projectfolder/:id/products",
                  "/projects/projectfolder/:id/tasks/:taskId",
                  "/projects/projectfolder/:id/tasks/:taskId/:fileId/:versionId",
                  "/projects/projectfolder/:id/project-design/:designId",
                  "/projects/projectfolder/:id/project-design/:designId/:fileId",
                  "/projects/projectfolder/:id/project-design/:designId/:fileId/:versionId",
                  "/projects/projectfolder/:id/component-design/:manufItemId/:packagingComponentId",
                  "/projects/projectfolder/:id/component-design/:manufItemId/:packagingComponentId/:designId",
                  "/projects/projectfolder/:id/component-design/:manufItemId/:packagingComponentId/:designId/:fileId/:versionId",
                  "/projects/projectfolder/:id/manuf-item/:manufItemId",
                  "/projects/projectfolder/:id/product-design/:manufItemId/",
                  "/projects/projectfolder/:id/product-design/:manufItemId/:designId",
                  "/projects/projectfolder/:id/product-design/:manufItemId/:designId/:fileId/:versionId",
                  "/projects/projectfolder/:id/*",
                ]}
                component={ProjectFolder}
              />
              <AuthorizedRoute
                isActive={hasAccessToApplication}
                exact
                path={[
                  "/admin/workflow-management",
                  "/admin/workflow-management/*",
                  "/admin/user-management",
                  "/admin/user-management/*",
                ]}
                component={Administration}
              />
              <AuthorizedRoute
                isActive={hasAccessToApplication}
                exact
                path="/pocpdftron"
                component={PocPDFTron}
              />
              <AuthorizedRoute
                isActive={hasAccessToApplication}
                exact
                path="/home"
                component={Home}
              />
              <AuthorizedRoute
                isActive={hasAccessToApplication}
                exact
                path="/"
                render={() => <Redirect to="/home" push={false} />}
              />
              <Route exact path="/error/:status" component={Errors} />
              <Route path="*" component={Errors} />
            </Switch>
          </Suspense>
        </MainLayout>
      </Router>
    </ProjectAppMainContextProvider>
  );
};
export default Routes;
