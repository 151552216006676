import { Gantt, LocaleManager } from "bryntum-gantt";
import React, { useCallback, useEffect, useRef } from "react";
import { useIntl } from "react-intl";
import "./BryntumComponent.less";
import "./locale/gantt.locale.Fr.js";
import "./EstimatedDurationColumn";
import "./StatusColumn";
import "./TaskPriorityColumn";
import "./InitialEndDateColumn";
import "./EstimatedEndDateColumn";
import "./EndDateColumn";
import "./TimeTrackedColumn";
import { useDispatch, useSelector } from "react-redux";
import {
  selectDisplayMyTasks,
  selectShowFutureTasks,
  selectExpanded,
  selectIsLockedCollpased,
  selectIsNormalCollpased,
  selectShowCriticalPath,
  selectSliderPosition,
} from "common/selectors/ganttView.selector";
import {
  createBryntumFilter,
  getExpandColapseInfoFromStore,
  storeExpandCollapseInfo,
  deleteExpandCollapseInfo,
  wait,
  areWeStillInSameProject,
  setProjectIDFromURL,
} from "./Utils";
import {
  setBryntumColumnSize,
  setExpanded,
  setIsLockedCollapsed,
  setIsNormalCollapsed,
  setSliderPosition,
} from "redux/actions/GanttView";
import { debounce } from "lodash";
import { useMutationObserver } from "./useMutationObserver";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";

export const BryntumProject = ({
  projectStatus,
  config,
  onGanttReady,
  ganttRef,
  isGanttViewFiltered = false,
}) => {
  const elementRef = useRef();
  const canStoreExpandColapseInfo = useRef({
    timerSet: false,
    canWeStart: false,
  });
  const displayMyTasks = useSelector(selectDisplayMyTasks);
  const displayFutureTasks = useSelector(selectShowFutureTasks);
  const expanded = useSelector(selectExpanded);
  const showCriticalPath = useSelector(selectShowCriticalPath);
  const isLockedCollapsed = useSelector(selectIsLockedCollpased);
  const isNormalCollapsed = useSelector(selectIsNormalCollpased);
  const sliderPosition = useSelector(selectSliderPosition);
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!areWeStillInSameProject(location.pathname)) {
      // remove old data if we navigated to diffrent project
      deleteExpandCollapseInfo();
      setProjectIDFromURL(location.pathname);
    }
  }, [location?.pathname]);

  const applyLocale = code => {
    switch (code) {
      case "fr-FR":
      case "fr":
        LocaleManager.applyLocale("Fr");
        break;
      default:
        LocaleManager.applyLocale("En");
        break;
    }
  };

  const intl = useIntl();
  const observerConfig = { attributes: true };

  const debouncedMutationObserverCallback = debounce(async () => {
    const parentElement = document.querySelector(
      ".b-grid-body-container"
    )?.clientWidth;

    const targetElement = document.querySelector(
      "[id ^= b-gantt-] [id $= -lockedSubgrid-header]"
    )?.clientWidth;

    const slider = document.querySelector(".b-grid-splitter")?.clientWidth;

    dispatch(
      setSliderPosition(
        `${Math.floor((targetElement / (parentElement - slider)) * 100)}%`
      )
    );
  }, 250);

  const observer = useMutationObserver(debouncedMutationObserverCallback);

  const showExpandView = () => {
    if (expanded) {
      ganttRef.current.expandAll();
    } else {
      const childrens = config.project.children[0]?.children;
      if (childrens && childrens.length > 0) {
        childrens.forEach(({ id }) => ganttRef.current.collapse(id));
      } else ganttRef.current.collapseAll();
    }
  };
  const showMyTasksView = () => {
    const taskStore = ganttRef.current.taskStore;
    taskStore.clearFilters();
    taskStore.filterBy(
      createBryntumFilter(displayMyTasks, displayFutureTasks, config)
    );
    if (displayMyTasks || displayFutureTasks) {
      ganttRef.current.expandAll();
      dispatch(setExpanded(true));
    } else {
      if (expanded) {
        // This will keep expanded the tasks as they were already
        ganttRef.current.expandAll();
      }
    }
  };
  const showCriticalPathView = () => {
    ganttRef.current.features.criticalPaths.disabled = !showCriticalPath;
  };

  const toggleCollapse = e => {
    if (e === "locked") {
      dispatch(
        setIsLockedCollapsed({
          isLockedCollapsed: true,
          sliderPosition: "0",
        })
      );
      return;
    }
    dispatch(
      setIsNormalCollapsed({ isNormalCollapsed: true, sliderPosition: "100%" })
    );
  };

  const toggleExpand = e => {
    if (e === "locked") {
      dispatch(
        setIsLockedCollapsed({
          isLockedCollapsed: false,
          sliderPosition: "50%",
        })
      );
      return;
    }
    dispatch(
      setIsNormalCollapsed({ isNormalCollapsed: false, sliderPosition: "50%" })
    );
  };

  const setGantViewPossition = () => {
    ganttRef.current.subGrids.locked.collapsed = isLockedCollapsed;
    ganttRef.current.subGrids.normal.collapsed = isNormalCollapsed;
    if (sliderPosition) {
      ganttRef.current.subGrids.locked.width = sliderPosition;
    }
  };

  useEffect(() => {
    applyLocale(intl.locale);
    if (config) {
      setData();
    }

    return () => {
      if (ganttRef.current) {
        ganttRef.current.destroy();
      }
    };
  }, [config]);

  const setLockedTaskConfiguration = useCallback(() => {
    const { lockedTaskId, tasks } = ganttRef.current.project;
    if (lockedTaskId && projectStatus === "Draft") {
      let isCurrentTaskReached = false;
      tasks.forEach(task => {
        const { isUserTask, id: taskId } = task.originalData;

        if (isUserTask) {
          if (!isCurrentTaskReached) {
            if (lockedTaskId === taskId) {
              task.setConstraintType("mustfinishon");
              task.setConstraintDate(task.originalData.dueDateUtc);
            } else {
              task.setDirection("Backward");
            }
          } else {
            task.setDirection("Forward");
          }
        }
      });
    }
  }, [projectStatus]);

  const expandTaskById = async taskId => {
    const taskRecord = ganttRef.current.taskStore.getById(taskId);

    if (taskRecord) {
      // Check if the task has parent tasks and expand them
      let parentTask = taskRecord.parent;
      while (parentTask) {
        if (!parentTask.isExpanded()) {
          await ganttRef.current.expand(taskRecord);
        }
        parentTask = parentTask.parent;
      }

      // Finally, expand the task itself if it is a group or collapsed
      if (taskRecord.isExpanded === false) {
        taskRecord.expand();
      }
    }
  };

  const collapseTaskById = async taskId => {
    // Get the task by its ID from the taskStore
    const taskRecord = ganttRef.current.taskStore.getById(taskId);

    if (taskRecord) {
      // If the task is a parent (group), collapse it
      if (taskRecord.isExpanded()) {
        await ganttRef.current.collapse(taskRecord);
      }
    }
  };

  const canIStartStoringExpandCollapseInfo = () => {
    if (canStoreExpandColapseInfo.current.canWeStart) {
      return true;
    }

    if (!canStoreExpandColapseInfo.current.timerSet) {
      canStoreExpandColapseInfo.current.timerSet = true;
      window.setTimeout(() => {
        canStoreExpandColapseInfo.current.canWeStart = true;
      }, 1000);

      return canStoreExpandColapseInfo.current.canWeStart;
    } else {
      return false;
    }
  };

  const scrollToWorkingTask = async () => {
    const taskId = sessionStorage.getItem("currentTaskId");

    if (taskId) {
      const taskRecord = ganttRef.current.taskStore.getById(taskId);
      if (!taskRecord) {
        return;
      }

      try {
        await ganttRef.current.scrollTaskIntoView(taskRecord);
        await wait(300);
        document.querySelector(`[data-id="${taskId}"]`)?.scrollIntoView();
      } catch (error) {
        console.warn(error);
      }
    }
  };

  const applyExpandCollapseBasedOnStoredState = async () => {
    await wait(1000);
    const data = getExpandColapseInfoFromStore();
    if (data) {
      Object.entries(data).forEach(([taskId, colapsed]) => {
        if (colapsed) {
          collapseTaskById(taskId);
        } else {
          expandTaskById(taskId);
        }
      });

      await wait(300);
      await scrollToWorkingTask();
    }
  };

  const setData = () => {
    if (ganttRef.current) {
      ganttRef.current.destroy();
    }

    ganttRef.current = new Gantt({
      ...config,
      listeners: {
        ...config.listeners,
        beforeTaskResize: ({ taskRecord }) => {
          const isTskLocked =
            taskRecord.originalData?.hardDateConstraint?.EndDate;
          return !isTskLocked;
        },
        columnResize: ({ column: { data } }) => {
          const { field: columnName, width: columnWidth } = data;
          dispatch(
            setBryntumColumnSize({
              columnName,
              columnWidth,
            })
          );
        },
        togglenode: ({ record, collapse, ...rest }) => {
          if (canIStartStoringExpandCollapseInfo()) {
            storeExpandCollapseInfo(record.originalData.id, collapse);
          }
        },
        subgridcollapse: e => {
          toggleCollapse(e.region);
        },
        subgridexpand: e => {
          toggleExpand(e.region);
        },
      },
      appendTo: elementRef.current,
    });

    ganttRef.current.project.on({
      load: t => {
        onGanttReady();
        setLockedTaskConfiguration();
        applyExpandCollapseBasedOnStoredState();
        if (isGanttViewFiltered) {
          showExpandView();
          showMyTasksView();
          showCriticalPathView();
          setGantViewPossition();
          const targetNode = document.querySelector(
            "[id ^= b-gantt-] [id $= -lockedSubgrid-header]"
          );

          if (targetNode) {
            observer.observe(targetNode, observerConfig);
          }
        }
      },
    });
  };

  return <div className="project-gantt" ref={elementRef} />;
};

BryntumProject.propTypes = {
  projectStatus: PropTypes.string,
  config: PropTypes.any,
  onGanttReady: PropTypes.func,
  ganttRef: {
    current: PropTypes.any,
  },
  isGanttViewFiltered: PropTypes.bool,
};
