import { Column, ColumnStore } from "bryntum-gantt";
import styles from "routes/Tasks/TasksList/components/TableTasks.module.less";
import moment from "moment";
import { Tag } from "@trace-one/design-system";
import { renderToString } from "react-dom/server";
import { wait } from "./Utils";
import EstimatedEndDateCell from "./EstimatedEndDateCell";

export default class EstimatedEndDateColumn extends Column {
  static get type() {
    return "dueDateUtc";
  }

  static get isGanttColumn() {
    return true;
  }

  static get defaults() {
    return {
      text: "Duration",
      htmlEncode: false,
    };
  }

  static getTimeFormat(wasRun) {
    return wasRun ? "DD/MM/YYYY HH:mm" : "DD/MM/YYYY";
  }

  static getColor(endDate, initialDueDateUtc, status) {
    const initialDueDate = moment(initialDueDateUtc).format("YYYY-MM-DD HH:mm");
    const endDateFormatted = moment(endDate).format("YYYY-MM-DD HH:mm");

    if (initialDueDate == null || endDateFormatted <= initialDueDate)
      return "green";
    if (endDateFormatted > initialDueDate) return "orange";
    if (
      ["InProgress", "Future"].includes(status) &&
      moment().format("YYYY-MM-DD HH:mm") > initialDueDate
    )
      return "orange";

    return "green";
  }

  static statusCheck(status) {
    return ["Approved", "Completed", "Rejected"].includes(status);
  }

  renderer({
    record: { originalData, endDate },
    column: {
      data: { wasRun },
    },
  }) {
    const { initialDueDateUtc, stepStatus, taskStatus, id, endDateUtc } =
      originalData;
    const status = taskStatus || stepStatus || "Undefined";

    if (
      EstimatedEndDateColumn.statusCheck(status) ||
      (id === "projectRow" && endDateUtc)
    )
      return "-";

    return renderToString(
      <Tag
        className={styles.setTagEstimatedEndDate}
        color={EstimatedEndDateColumn.getColor(
          endDate,
          initialDueDateUtc,
          status
        )}
        label={moment(endDate).format(
          EstimatedEndDateColumn.getTimeFormat(wasRun)
        )}
        size="medium"
        mode="light"
      />
    );
  }
}

export class EstimatedEndDateColumnCreation extends EstimatedEndDateColumn {
  static get type() {
    return "dueDateUtcCreation";
  }

  static getColor(endDate, initialDueDateUtc, status) {
    const initialDueDate = moment(initialDueDateUtc).format("YYYY-MM-DD HH:MM");
    const endDateFormatted = moment(endDate).format("YYYY-MM-DD HH:MM");
    let colorValue = "green";

    if (endDateFormatted > initialDueDate) {
      colorValue = "orange";
    }

    if (
      (status === "InProgress" || status === "Future") &&
      moment().format("YYYY-MM-DD HH:MM") > initialDueDate
    ) {
      colorValue = "orange";
    }

    return colorValue;
  }

  static statusCheck(status) {
    return ["Approved", "Completed", "Rejected"].includes(status);
  }

  renderer({ record, rowElement, grid, column }) {
    const {
      originalData: {
        initialDueDateUtc,
        stepStatus,
        taskStatus,
        id,
        endDateUtc,
        canBeLocked,
        hardDateConstraint,
        estimatedEndDateCheckBox,
      },
      endDate,
    } = record;

    const {
      data: { EstimatedEndDatecheckBoxChange },
    } = column;

    const {
      store: { records },
    } = grid;

    const disableOtherCells = () => {
      const anyChecked = records.some(
        rec =>
          rec.originalData.estimatedEndDateCheckBox ||
          rec.originalData.hardDateConstraint?.EndDate
      );

      records.forEach(rec => rec.set("disabled", anyChecked));
    };

    grid.on("beforeCellEditStart", event => {
      const { editorContext } = event;
      const { record, column } = editorContext;
      const isChecked = record.originalData?.estimatedEndDateCheckBox;
      if (column.field === "fullDuration") {
        return !isChecked;
      }

      if (column.field === "endDate") {
        const canBeLocked = record.originalData?.canBeLocked;
        const columnChecked = isChecked || !!hardDateConstraint?.EndDate;
        return !columnChecked && canBeLocked;
      }
      return true;
    });

    const isEstimatedEndDateChecked =
      !!estimatedEndDateCheckBox || !!hardDateConstraint?.EndDate;
    const status = taskStatus || stepStatus || "Undefined";

    if (
      EstimatedEndDateColumn.statusCheck(status) ||
      (id === "projectRow" && endDateUtc)
    ) {
      return "-";
    }

    const onCheckboxChange = e => {
      EstimatedEndDatecheckBoxChange({ record, records, target: e.target });
      const isChecked = e.target.checked;

      record.originalData.estimatedEndDateCheckBox = isChecked;
      record.originalData.hardDateConstraint.EndDate = isChecked;
      record.originalData.locked = isChecked;
      record.set("dueDateUtcCreation", isChecked);

      rowElement.style.backgroundColor = isChecked ? "#F5E8FF" : "white";

      disableOtherCells();
    };

    wait(0).then(() => {
      const checkboxElement = document.getElementById(`checkbox-${record.id}`);
      if (checkboxElement) {
        checkboxElement.removeEventListener("change", onCheckboxChange);
        checkboxElement.addEventListener("change", onCheckboxChange);

        checkboxElement.checked = isEstimatedEndDateChecked;
        checkboxElement.disabled = records.some(
          rec =>
            (rec.originalData.estimatedEndDateCheckBox ||
              rec.originalData.hardDateConstraint?.EndDate) &&
            rec.id !== record.id
        );
      }

      rowElement.style.backgroundColor = isEstimatedEndDateChecked
        ? "#F5E8FF"
        : "white";
    });

    return {
      html: renderToString(
        <EstimatedEndDateCell
          endDate={endDate}
          isEstimatedEndDateChecked={isEstimatedEndDateChecked}
          canBeLocked={canBeLocked}
          record={record}
          records={records}
          status={status}
          initialDueDateUtc={initialDueDateUtc}
          EstimatedEndDateColumn={EstimatedEndDateColumn}
        />
      ),
    };
  }
}

// Register both column types with the ColumnStore
ColumnStore.registerColumnType(EstimatedEndDateColumn);
ColumnStore.registerColumnType(EstimatedEndDateColumnCreation);
